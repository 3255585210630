<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진기간"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="검진 이력"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      @linkClick="linkClick"
      :columnSetting="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'click'">
          <q-chip
            color="blue"
            outline square
            :clickable="true"
            :editable="editable"
            text-color="white"
            @click="linkClick(props.row, col.name)">
            {{'클릭'}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'examine-history',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup2' },
          { index: 1, colName: 'mergeGroup2' },
          { index: 2, colName: 'mergeGroup2' },
          { index: 3, colName: 'mergeGroup2' },
          { index: 4, colName: 'mergeGroup' },
          { index: 11, colName: 'mergeGroup2' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 80px',
            label: '대상자',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            label: '판정결과',
            align: 'center',
            child: [
              {
                name: 'judgmentName',
                field: 'judgmentName',
                label: '판정',
                style: 'width: 50px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'diseaseName',
                field: 'diseaseName',
                label: '질환',
                style: 'width: 80px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'hazardNames',
                field: 'hazardNames',
                label: '관련유해인자',
                style: 'width: 400px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'specialMeasures',
                field: 'specialMeasures',
                label: '특수조치',
                align: 'center',
                style: 'width: 100px',
                sortable: true,
              },
              {
                name: 'specialSuitability',
                field: 'specialSuitability',
                label: '특수업무적합성',
                align: 'center',
                style: 'width: 100px',
                sortable: true,
              },
              {
                name: 'remark',
                field: 'remark',
                label: 'LBLREMARK',
                align: 'left',
                style: 'width: 120px',
                sortable: true,
              },
            ]
          },
          {
            name: 'click',
            field: 'click',
            label: '검진항목',
            align: 'center',
            type: 'custom',
            style: 'width: 100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      examineUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.examineUrl = selectConfig.hea.checkup.suspect.history.examine.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.examineUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./checkUpResultExamine.vue"}`);
      this.popupOptions.title = '검진항목 결과 상세';
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.param = {
        heaCheckupResultId: result ? result.heaCheckupResultId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
